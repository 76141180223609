import React from 'react';
import { useTranslate } from 'react-admin';
import { Alert } from '@material-ui/lab';
import { Search } from '@material-ui/icons';

import { List, Show, Create, Edit, Pagination } from '../../common/ra';
import ResourceTitle, { ResourceAvatarTitle } from '../../common/ResourceTitle';

import { ParticipantGrid } from './ParticipantGrid';
import { ParticipantFilters } from './ParticipantFilters';
import { ParticipantShowLayout } from './ParticipantShow';
import { ParticipantForm } from './ParticipantForm';

const ParticipantPagination = ({ total, ...props }) => {
  const translate = useTranslate();
  if (total < 0) return <Alert severity="info" icon={<Search />}>{translate('resources.participants.alerts.search_by_name_hockeyid')}</Alert>
  return <Pagination total={total} {...props} />
}

export const ParticipantList = props =>
  <List {...props}
    filters={<ParticipantFilters />}
    filter={{ _include: 'accounts' }}
    sort={{
      field: 'lastName',
      order: 'ASC'
    }}
    pagination={<ParticipantPagination />}
  >
    <ParticipantGrid rowClick="show" />
  </List>

const getTitle = participant => participant.fullName

export const ParticipantShow = props =>
  <Show {...props}
    title={<ResourceAvatarTitle title={getTitle} />}
  >
    <ParticipantShowLayout />
  </Show>

export const ParticipantCreate = props => {
  const translate = useTranslate();
  return <Create
    title={<ResourceTitle title={record => translate('resources.participants.labels.add')} />}
    {...props}
  >
    <ParticipantForm redirect="show" />
  </Create>
}

export const ParticipantEdit = props => {
  const translate = useTranslate();
  return <Edit
    title={<ResourceTitle title={record => translate('ra.page.editing', { name: getTitle(record) })} />}
    {...props}
  >
    <ParticipantForm redirect="show" />
  </Edit>
}
