import React from 'react';
import { Datagrid, TextField, useTranslate } from 'react-admin';
import { SupervisorAccountOutlined } from '@material-ui/icons';
import { Chip, makeStyles } from '@material-ui/core';

import FunctionField from '../../common/fields/FunctionField'

const useStyles = makeStyles(theme => ({
  ssoChip: {
    color: theme.palette.lightBlue[500],
    borderColor: theme.palette.lightBlue[500],
    backgroundColor: theme.palette.lightBlue[50],
  },
  ssoEmail: {
    marginRight: theme.spacing(1)
  },
  ssoIcon: {
    color: theme.palette.lightBlue[500]
  }
}))

export const ParticipantGrid = props =>
  <Datagrid size="medium" {...props}>
    <FunctionField source="lastName" label="resources.participants.fields.name" render={participant => participant.fullName} />
    <TextField source="HCRId" />
    <FunctionField source="accounts.email" label="resources.participants.fields.email" render={participant => <ParticipantEmailAccount participant={participant} />} />
  </Datagrid>

const ParticipantEmailAccount = ({ participant }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return participant.accounts && participant.accounts[0]?.externalId
    ? <>
      <span className={classes.ssoEmail}>{participant.accounts[0]?.email}</span>
      <Chip
        className={classes.ssoChip}
        size="small"
        variant="outlined"
        label={translate('components.login.chip.sso')}
        icon={<SupervisorAccountOutlined className={classes.ssoIcon} style={{ marginLeft: 8 }} />}
      />
    </>
    : <span>{participant.accounts && participant.accounts[0]?.email}</span>
}
