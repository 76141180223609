import React from 'react';
import { Datagrid, useRecordContext } from 'react-admin';
import { useSelector } from 'react-redux';

import { FF_SSO } from '@hisports/common/featureFlags';

import ListCard from '../../../common/cards/ListCard';
import { isAuthorized } from '../../../common/Authorize';
import { useFlag } from '../../../http';

import { IdentityGrid } from '../../identities/IdentityGrid';
import { AccountIcon } from '../../icons';
import { InviteButton, SsoInviteButton } from './ParticipantCard';

export default props => {
  const participant = useRecordContext(props);
  const identities = useSelector(store => store.admin.resources.identities.data);
  const isEnabled = useFlag();

  const showIdentities = isAuthorized(participant, 'identities', 'show');
  if (!showIdentities) return null;

  const hasIdentity = Object.keys(identities)
    .map(id => identities[id])
    .some(identity => identity.participantId === participant.id);

  const canInvite = !hasIdentity && isAuthorized(participant, 'participants', 'invite');
  const canSsoInvite = isEnabled(FF_SSO);
  const Invite = (canInvite && !canSsoInvite)
    ? <InviteButton participant={participant} />
    : (canInvite && canSsoInvite ? <SsoInviteButton participant={participant} /> : null);

  const onRowClick = (id, basePath, record) => `/accounts/${record.accountId}/show`

  return <ListCard
    title="resources.accounts.labels.card.title"
    icon={<AccountIcon fontSize="small" />}
    reference="identities"
    target="participants"
    rowsPerPageOptions={[]}
    addButton={Invite}
    collapsable
  >
    <IdentityGrid showParticipant={false} component={Datagrid} rowClick={onRowClick} />
  </ListCard>
}
