import React, { Fragment, useState } from 'react';
import { SimpleForm, FormInput, TextInput, useNotify, useTranslate, useRefresh } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core'
import { FORM_ERROR } from 'final-form';

import { apiClient } from '../../http';
import { DialogFormToolbar } from '../../common/dialogs/DialogForm';

const validate = values => {
  const errors = {};
  if (!values.email) errors.email = 'ra.validation.required';
  return errors;
}

const SsoInviteForm = props =>
  <SimpleForm component={Fragment} validate={validate} variant="outlined" margin="normal" {...props}>
    <FormInput fullWidth input={
      <TextInput resource="participants" source="email" type="email" autoComplete="off" />
    } />
  </SimpleForm>

const SsoSMACreateDialog = ({ participantId, email, open, onClose }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleCancel = () => onClose()
  const handleSubmit = async () => {
    return apiClient(`/participants/${participantId}/ssoCreateSMA`, {
      method: 'POST',
      data: {
        email,
      },
    })
      .then(() => {
        onClose();
        notify('resources.participants.notifications.sma_created_and_linked', 'success');
        refresh();
      })
      .catch(err => {
        const code = err?.response?.data?.error?.name

        return { [FORM_ERROR]: translate(`resources.participants.errors.${code}`) }
      })
  }

  return <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
    <DialogTitle>{translate('resources.participants.labels.account_invitation_sso')}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        <div style={{ color: 'red' }}>{translate('resources.participants.errors.no_sma_user_found')}</div>
        <br />
        <div>{translate('resources.participants.messages.create_sma')}</div>
      </DialogContentText>
      <SimpleForm component={Fragment} variant="outlined" margin="normal" save={handleSubmit} toolbar={
        <DialogFormToolbar submitLabel="ra.action.confirm" onCancel={handleCancel} />
      } >
        <FormInput fullWidth input={
          <TextInput disabled defaultValue={email} resource="participants" source="email" type="email" />
        } />
      </SimpleForm>
    </DialogContent>
  </Dialog>
}

export const SsoInviteDialog = ({ participantId, open, onClose }) => {
  const [ openCreateSMA, setOpenCreateSMA ] = useState(false);
  const [ smaEmail, setSmaEmail ] = useState();
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  const handleCancel = () => onClose()
  const handleSubmit = async values => {
    setSmaEmail(values.email);

    return apiClient(`/participants/${participantId}/ssoInvite`, {
      method: 'POST',
      data: {
        email: values.email,
      },
    })
      .then(() => {
        onClose();
        notify('resources.participants.notifications.sso_created_and_linked', 'success');
        refresh();
      })
      .catch(err => {
        const code = err?.response?.data?.error?.name

        if (code === 'no_sma_user_found') {
          onClose();
          setOpenCreateSMA(true);
          return;
        }

        return { [FORM_ERROR]: translate(`resources.participants.errors.${code}`) }
      })
  }

  return <>
    <Dialog open={open} onClose={handleCancel} fullWidth maxWidth="sm">
      <DialogTitle>{translate('resources.participants.labels.account_invitation_sso')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{translate('resources.participants.messages.link_sso')}</DialogContentText>
        <SsoInviteForm initialValues={{ participantId }} save={handleSubmit} toolbar={
          <DialogFormToolbar submitLabel="ra.action.send" onCancel={handleCancel} />
        } />
      </DialogContent>
    </Dialog>
    <SsoSMACreateDialog participantId={participantId} email={smaEmail} open={openCreateSMA} onClose={() => setOpenCreateSMA(false)} />
  </>
}
